import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig'; // Import Firestore as db
import { getDoc, getDocs, doc, collection } from 'firebase/firestore'; // Firestore imports
import './SearchComponent.css';
import axios from 'axios';

function processTokens(tokenString) {
  const tokens = tokenString.split(',').map(token => {
    const [name, quantity] = token.split('[');
    return {
      name: name.replace(/\s/g, ''), // remove all spaces from name
      quantity: quantity ? quantity.replace(']', '').replace(/\s/g, '') : null
    };
  }).filter(token => parseFloat(token.quantity) !== 0);
  return tokens;
}

const useTokenPrices = () => {
  const [tokenPrices, setTokenPrices] = useState({});

  useEffect(() => {
    const fetchTokenPrices = async () => {
      const snapshot = await getDocs(collection(db, "TokenPrices"));
      const prices = {};
      snapshot.forEach(doc => {
        // Remove special characters like asterisks from the document name
        const cleanDocId = doc.id.replace(/[*]/g, '');
        // Check if Price exists and is a string
        const priceData = doc.data().Price;
        if (typeof priceData === 'string') {
          // Parse to float after removing commas
          const price = parseFloat(priceData.replace(/,/g, ''));
          prices[cleanDocId] = isNaN(price) ? 0 : price;
        } else {
          prices[cleanDocId] = 0;
        }
      });
      setTokenPrices(prices);
    };
    fetchTokenPrices();
  }, []);

  return tokenPrices;
};

function formatNumber(num) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  }).format(num);
}

function formatTokenQuantity(num) {
  try {
    if (num == null) {
      return '0';
    }
    const normalizedNum = typeof num === 'number' ? num : Number(num);
    if (isNaN(normalizedNum)) {
      console.warn('Invalid number input:', num);
      return '0';
    }
    const numStr = String(normalizedNum);
    const fullNumStr = numStr.includes('e')
      ? normalizedNum.toPrecision(18).replace(/\.?0+$/, "")
      : numStr;
    const [integerPart, decimalPart] = fullNumStr.split('.');
    if (!decimalPart) return integerPart;

    const trimmedDecimalPart = decimalPart.replace(/0+$/, '');
    if (trimmedDecimalPart.length === 0) return integerPart;
    return `${integerPart}.${trimmedDecimalPart}`;
  } catch (error) {
    console.warn('Error formatting number:', error);
    return '0';
  }
}

function calculateTotalClaimSize(tokens, prices) {
  let total = 0;
  for (let token of tokens) {
    const tokenPrice = prices[token.name] || 0;
    let quantity = parseFloat(token.quantity);
    if (isNaN(quantity)) {
      quantity = 0;
    }
    if (!isNaN(tokenPrice) && !isNaN(quantity)) {
      total += quantity * tokenPrice;
    }
  }
  return total;
}

function SearchComponent() {
  // STEP 1: Parse query params on mount
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramCustomerNumber = params.get('customerNumber') || '';
    const paramOption = params.get('option') || 'june'; // default is june

    // Set them in state
    setCustomerNumber(paramCustomerNumber);
    setSelectedOption(paramOption);
  }, []);

  // Initialize states (we'll overwrite them in the useEffect above if found)
  const [customerNumber, setCustomerNumber] = useState('');
  const [customerData, setCustomerData] = useState(null);
  const [claimSize, setClaimSize] = useState(0);
  const [selectedOption, setSelectedOption] = useState('june'); 
  const tokenPrices = useTokenPrices(); 
  const [sortByValue, setSortByValue] = useState(false);

  // STEP 2: If the user came to a link with params, auto-load the data once
  useEffect(() => {
    if (initialLoad && customerNumber) {
      // If we do have a customerNumber from the URL, let's auto-search
      handleSearch();
      setInitialLoad(false);
    }
  }, [customerNumber, initialLoad]); 

  const toggleSortOrder = () => {
    setSortByValue(!sortByValue);
  };

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    setCustomerData(null);
    setSearchPerformed(false);
  };

  let collectionName = 'ftxfilings';
  let TokenName = 'TotalTokens';
  if (selectedOption === 'march') {
    collectionName = 'CustomerAccountsMarchData';
    TokenName = 'Token';
  } else if (selectedOption === 'january') {
    collectionName = 'CustomerAccountsJanSchFData';
    TokenName = 'Token';
  } else if (selectedOption === 'june') {
    collectionName = 'ftxfilings';
    TokenName = 'TotalTokens';
  }

  const handleCustomerNumberChange = (e) => {
    setCustomerNumber(e.target.value);
    setSearchPerformed(false);
  };

  const handleSearch = async () => {
    try {
      // STEP 3: Update the URL with the current search
      const newParams = new URLSearchParams(window.location.search);
      newParams.set('customerNumber', customerNumber);
      newParams.set('option', selectedOption);
      window.history.replaceState({}, '', `${window.location.pathname}?${newParams.toString()}`);

      // Fetch from the relevant collection
      const customerRef = doc(db, collectionName, customerNumber);
      const customerDoc = await getDoc(customerRef);

      // Also fetch Verification_Status from the main ftxfilings
      const ftxfilingsRef = doc(db, "ftxfilings", customerNumber);
      const ftxfilingsDoc = await getDoc(ftxfilingsRef);

      if (customerDoc.exists() || ftxfilingsDoc.exists()) {
        const data = customerDoc.exists() ? customerDoc.data() : {};

        // Merge Verification_Status from ftxfilings if it exists
        if (ftxfilingsDoc.exists()) {
          data.Verification_Status = ftxfilingsDoc.data().Verification_Status;
        }

        // If there's no Docket_Number, let the user know
        if (!data.hasOwnProperty("Docket_Number")) {
          setCustomerData(`NO CUSTOMER DATA FOR ${selectedOption}`);
          setSearchPerformed(true);
          return;
        }

        data.processedTokens = processTokens(data[TokenName] || "");
        setCustomerData(data);

        const calculatedClaimSize = calculateTotalClaimSize(data.processedTokens, tokenPrices);
        setClaimSize(calculatedClaimSize);
      } else {
        setCustomerData(`NO CUSTOMER DATA FOR ${selectedOption}`);
      }
      setSearchPerformed(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      setCustomerData("Error fetching data.");
      setSearchPerformed(true);
    }
  };

  const noDataMessage = `No Data For ${selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)} Schedules`;

  const sortedTokens = customerData && customerData.processedTokens
    ? [...customerData.processedTokens].sort((a, b) => {
        if (sortByValue) {
          // Sort by token value
          const aValue = tokenPrices[a.name] ? tokenPrices[a.name] * parseFloat(a.quantity) : 0;
          const bValue = tokenPrices[b.name] ? tokenPrices[b.name] * parseFloat(b.quantity) : 0;
          return bValue - aValue;
        } else {
          // Sort by token name
          return a.name.localeCompare(b.name);
        }
      })
    : [];

  return (
    <div className="container">
      <div className="search-box">
        <h1>Customer Code</h1>
        <input
          className="search-input"
          type="text"
          value={customerNumber}
          onChange={handleCustomerNumberChange}
          onKeyDown={(e) => { if (e.key === 'Enter') handleSearch(); }}
          placeholder="Enter Customer Code"
        />

        <select className="custom-dropdown" value={selectedOption} onChange={handleDropdownChange}>
          <option value="june">View June Sch F Data</option>
          <option value="march">View March Sch F Data</option>
          <option value="january">View Jan Sch F Data</option>
        </select>

        <button className="search-button" onClick={handleSearch}>Search</button>
      </div>

      {searchPerformed && (
        customerData && Array.isArray(customerData.processedTokens) ? (
          customerData.processedTokens.length > 0 ? (
            <div className="results">

              <table className="customer-table">
                <tbody>
                  <tr>
                    <td><b>Minimum Claim Amount</b></td>
                    <td><b>{claimSize ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(claimSize) : "N/A"}</b></td>
                  </tr>
                  <tr>
                    <td>Unique Customer Code</td>
                    <td>{customerNumber}</td>
                  </tr>
                  <tr>
                    <td>Omni 130 (Docket No. 29464) Exhibit C</td>
                    <td>{customerData.Verification_29350_3 === "Unverified" ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>Transfer Of Claim Docket Number (updated as of Docket No. 30005)</td>
                    <td>{customerData.Transfer_Record || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Page Number</td>
                    <td>{customerData.Page_Number || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Docket Number</td>
                    <td>{customerData.Docket_Number || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>CUD Indicator</td>
                    <td>{customerData.Indicator || customerData.Contingency || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Earn Indicator</td>
                    <td>{customerData.EarnIndicator || customerData.Earn || "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Token / Fiat in Lend</td>
                    <td>{customerData.TokenLend || customerData.Token_In_Lend || "N/A"}</td>
                  </tr>
                </tbody>
              </table>

              <div className="sort-by-container">
                <div className="toggle-switch">
                  <input
                    id="sortOrderToggle"
                    className="sort-order-toggle"
                    type="checkbox"
                    checked={sortByValue}
                    onChange={toggleSortOrder}
                  />
                  <label htmlFor="sortOrderToggle" className="toggle-label"></label>
                </div>
                <span>Sort by Petition Date Value</span>
              </div>

              <table className="tokens-table">
                <thead>
                  <tr>
                    <th>Coin</th>
                    <th>Quantity</th>
                    <th>Petition Date Pricing</th>
                    <th>Petition Date Value</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedTokens.map((token, index) => (
                    <tr key={index}>
                      <td>{token.name}</td>
                      <td>{formatTokenQuantity(token.quantity)}</td>
                      <td>
                        {tokenPrices[token.name] !== undefined
                          ? tokenPrices[token.name]
                          : "Fetching..."}
                      </td>
                      <td>
                        {tokenPrices[token.name] !== undefined
                          ? formatNumber(tokenPrices[token.name] * parseFloat(token.quantity))
                          : "Fetching..."}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>
          ) : (
            <div className="no-data-message">{noDataMessage}</div>
          )
        ) : (
          <div className="no-data-message">{noDataMessage}</div>
        )
      )}
    </div>
  );
}

export default SearchComponent;
